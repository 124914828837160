/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "ol/ol.css";
import "./MapOpenLayer.css";

// Import d3-scale
import moment from "moment";
import { Map, Overlay, View } from "ol";
import Control from "ol/control/Control";
import GeoJSON from "ol/format/GeoJSON.js";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector.js";
import WebGLTile from "ol/layer/WebGLTile";
import { transform } from "ol/proj.js";
// import { OSM } from "ol/source";
import GeoTIFF from "ol/source/GeoTIFF";
import VectorSource from "ol/source/Vector.js";
import XYZ from "ol/source/XYZ";
// import CircleStyle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Style from "ol/style/Style";
import { useEffect, useMemo, useRef, useState } from "react";

interface MapOpenLayerVectorFileProps {
  geoJsonFileUrl: string;
  featurePropertyName: string;
  fillColor1: string;
  fillColor2: string;
  middleColor?: string;
  isDateFilterRequired?: boolean;
}
interface MapOpenLayerFillColour {
  Colour: string;
  Opacity?: string;
  Text?: string;
}
interface MapOpenLayerRasterFileProps {
  geoTiffFileUrl: string;
  date: string;
}
interface MapOpenLayerRasterMinMax {
  minValue: number;
  maxValue: number;
  noDataValue?: number;
}
export interface MapOpenLayerGisFileProps {
  vectorFile?: MapOpenLayerVectorFileProps;
  rasterLayer?: MapOpenRasterLayerProps;
  projectShape: { geoJsonFileUrl: string; fillColor: string };
  mapContext?: MapOpenRasterContext;
}

export interface MapOpenRasterLayerProps {
  rasterFiles: MapOpenLayerRasterFileProps[];
  rasterFillColours: MapOpenLayerFillColour[];
  rasterMinMax: MapOpenLayerRasterMinMax;
}
export interface MapOpenRasterContext {
  mapContextHead: string;
  mapContextContent: string;
}

interface MapOpenLayerProps {
  coordinate: { lat: number; lng: number };
  gisFile: MapOpenLayerGisFileProps;
}

export const MapOpenLayer = ({ coordinate, gisFile }: MapOpenLayerProps): JSX.Element => {
  const MAPBOX_TOKEN = "pk.eyJ1Ijoiamlhbm5hbmx1IiwiYSI6ImNtM3BvdjNpdjBlN3oycXM0eXdmcWRxODkifQ._puZrLst8YIgoDn2sye1tQ";
  const mapElement = useRef<HTMLDivElement | null>(null);
  // Define the date range hardcoded for NOW
  // const [mapDateNum, setMapDateNum] = useState<number>(0);
  const [mapDateStr, setMapDateStr] = useState<string>("");
  let minValue = 0;
  let maxValue = 0;
  let noDataValue = 0;
  let color1 = "white";
  let color2 = "black";
  let midColour = "";
  // let middleColor: string;
  let color1Text: string | null = null;
  // let middleColorText: string | null = null;
  let color2Text: string | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let layers: any[];
  let mapContextHead: string | null = null;
  let mapContextContent: string | null = null;
  // let dateArray: string[] = [];

  // const osmLayer = useMemo(
  //   () =>
  //     new TileLayer({
  //       preload: Infinity,
  //       source: new OSM(),
  //     }),
  //   []
  // );

  const satelliteLayer = useMemo(
    () =>
      new TileLayer({
        source: new XYZ({
          url: `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`,
          tileSize: 512, // Mapbox uses 512px tiles by default
          maxZoom: 19, // Maximum zoom level for satellite tiles
        }),
      }),
    []
  );

  const shapeLayer = useMemo(
    () =>
      new VectorLayer({
        source: new VectorSource({
          url: gisFile?.projectShape.geoJsonFileUrl,
          format: new GeoJSON(),
        }),
        opacity: 0.5,
        style: new Style({
          fill: new Fill({
            color: gisFile?.projectShape.fillColor,
          }),
        }),
      }),
    []
  );

  const infoElement = document.createElement("div");
  infoElement.className = "info-overlay"; // Assign a class to the element
  const infoOverlay = new Overlay({
    element: infoElement,
    positioning: "bottom-left",
    stopEvent: false,
  });

  // const createColorScale = (): d3.ScaleLinear<string, string, never> => {
  //   // if (middleColor) {
  //   //   return d3
  //   //     .scaleLinear<string>() // Create a linear scale
  //   //     .domain([0, 50, 100]) // Input range (your data values)
  //   //     .range([color1, middleColor, color2])
  //   //     .clamp(true); // Output range (colors: green to red)
  //   // }
  //   return d3
  //     .scaleLinear<string>() // Create a linear scale
  //     .domain([0, 100]) // Input range (your data values)
  //     .range([color1, color2])
  //     .clamp(true); // Output range (colors: green to red)
  // };

  const hexToRGB = (hex: string, alpha?: string): string => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
  };

  // const getPointFeatureStyle = (value: number): Style => {
  //   const colorScale = createColorScale();
  //   const scaledValue = ((value - minValue) * 100) / (maxValue - minValue);
  //   return new Style({
  //     image: new CircleStyle({
  //       radius: scaledValue,
  //       fill: new Fill({
  //         color: colorScale(scaledValue),
  //       }),
  //     }),
  //   });
  // };

  // const getPolygonFeatureStyle = (value: number): Style => {
  //   const colorScale = createColorScale();
  //   const scaledValue = ((value - minValue) * 100) / (maxValue - minValue);
  //   return new Style({
  //     fill: new Fill({
  //       color: colorScale(scaledValue),
  //     }),
  //   });
  // };

  // if (gisFile?.vectorFile) {
  //   color1 = gisFile?.vectorFile.fillColor1;
  //   color2 = gisFile?.vectorFile.fillColor2;
  //   const vectorSource = new VectorSource({
  //     url: gisFile?.vectorFile?.geoJsonFileUrl,
  //     format: new GeoJSON(),
  //   });

  //   vectorSource.on("featuresloadend", () => {
  //     // const features = vectorSource.getFeatures();
  //     // let tempMin = 0;
  //     // let tempMax = 0;
  //     // features.forEach((feature) => {
  //     //   const val = feature.get(gisFile!.vectorFile!.featurePropertyName);
  //     //   if (gisFile?.vectorFile?.isDateFilterRequired) {
  //     //     dateArray = feature.get("date");
  //     //   }
  //     //   if (Array.isArray(val)) {
  //     //     tempMin = Math.min(tempMin, ...val);
  //     //     tempMax = Math.max(tempMax, ...val);
  //     //   } else {
  //     //     tempMin = Math.min(tempMin, val);
  //     //     tempMax = Math.max(tempMax, val);
  //     //   }
  //     // });
  //     // if (gisFile?.vectorFile?.middleColor) {
  //     //   middleColor = gisFile?.vectorFile?.middleColor;
  //     // }
  //     // maxValue = tempMax;
  //     // minValue = tempMin;
  //   });

  //   const vectorLayer = new VectorLayer({
  //     source: vectorSource,
  //     opacity: 0.5,
  //     style: (feature) => {
  //       const value = feature.get(gisFile!.vectorFile!.featurePropertyName);
  //       const geometry = feature.getGeometry();
  //       if (geometry?.getType() === "Point") {
  //         return getPointFeatureStyle(value);
  //       }
  //       // if (mapDate) {
  //       //   dateArray.indexOf(mapDate);
  //       //   return getPolygonFeatureStyle(value[dateArray.indexOf(mapDate)]);
  //       // }
  //       return getPointFeatureStyle(value);
  //     },
  //   });
  //   layers = [satelliteLayer, vectorLayer];
  // } else if (gisFile?.rasterLayer) {
  //   color1 = gisFile?.rasterLayer.rasterFillColours[0].Colour;
  //   color1Text = gisFile?.rasterLayer.rasterFillColours[0].Text || null;
  //   color2 = gisFile?.rasterLayer.rasterFillColours.at(-1)!.Colour;
  //   color2Text = gisFile?.rasterLayer.rasterFillColours.at(-1)!.Text || null;

  //   // dateArray = gisFile?.rasterLayer.rasterFiles.map((x) => x.date);
  //   setMapDateStr(gisFile?.rasterLayer.rasterFiles[0].date);
  //   const geoTiffSource = new GeoTIFF({
  //     sources: [
  //       {
  //         url: gisFile?.rasterLayer.rasterFiles.find((x) => x.date === mapDateStr)?.geoTiffFileUrl,
  //         nodata: NaN,
  //       },
  //     ],
  //     interpolate: false,
  //     normalize: false,
  //   });
  //   const webGLLayer = new WebGLTile({
  //     source: geoTiffSource,
  //     style: {
  //       color: [
  //         "interpolate", // Use a value mapping approach
  //         ["linear"], // Define the interpolation type
  //         ["band", 1], // Read data from band 1 of the GeoTIFF
  //         -99999,
  //         `rgba(0, 0, 0, 0)`,
  //         minValue,
  //         hexToRGB(color1, gisFile?.rasterLayer.rasterFillColours[0].Opacity || "0"), // Map the minimum value (e.g., 0) to color1
  //         maxValue,
  //         hexToRGB(color2, "1"), // Map the maximum value (e.g., 255) to color2
  //       ],
  //     },
  //   });

  //   layers = [satelliteLayer, webGLLayer];
  // } else {
  //   layers = [satelliteLayer, shapeLayer];
  // }

  layers = [satelliteLayer, shapeLayer];

  const createColorLegend = (): HTMLDivElement => {
    const legend = document.createElement("div");

    // const middleColorText = "";
    // if (middleColor) {
    //   legend.innerHTML = `
    //   <div class="scale">
    //     <div class="scale-bar" style="background: linear-gradient(to right, ${color1}, ${middleColor}, ${color2})"></div>
    //     <div class="scale-labels">
    //       <span>${color1Text || minValue}</span>
    //       <span>${middleColorText || (minValue + maxValue) / 2}</span>
    //       <span>${color2Text || maxValue}</span>
    //     </div>
    //   </div>
    // `;
    // } else {
    if (gisFile.rasterLayer && gisFile.rasterLayer.rasterFillColours.length <= 3) {
      legend.className = "color-legend";
      legend.innerHTML = `
      <div class="scale">
        <div class="scale-bar" style="background: linear-gradient(to right, ${color1}, ${midColour ? `${midColour}` : `${color2}`}, ${color2})"></div>
        <div class="scale-labels">
          <span>${color1Text || minValue}</span>
          <span>${color2Text || maxValue}</span>
        </div>
      </div>
    `;
    } else {
      legend.className = "legend-container";
      gisFile.rasterLayer?.rasterFillColours.forEach((item) => {
        legend.innerHTML += `
        <div class="legend-item">
          <div class="legend-color" style="background-color:${item.Colour}"></div>
          <div class="legend-text">${item.Text}</div>
        </div>`;
      });
    }
    // }
    return legend;
  };

  const createDateSelector = (): HTMLDivElement => {
    const beginDate = moment(20230101, "YYYYMMDD");
    const endDate = moment(20241231, "YYYYMMDD");
    // const days = endDate.diff(beginDate, "days");
    const dateOptions = gisFile?.rasterLayer!.rasterFiles.map((x) => x.date);
    const sliderPositions = dateOptions!.map(
      (date) => (moment(date, "YYYYMMDD").diff(beginDate, "days") / endDate.diff(beginDate, "days")) * 100
    );
    // const firstDateStr = gisFile?.rasterLayer?.rasterFiles[0].date;
    // const firstDate = moment(firstDateStr, "YYYYMMDD");
    // const firstDays = firstDate.diff(beginDate, "days");
    // const lastDateStr = gisFile?.rasterLayer?.rasterFiles.at(-1)?.date;
    // const lastDate = moment(lastDateStr, "YYYYMMDD");
    // const lastDays = lastDate.diff(beginDate, "days");
    const currentMapStr = gisFile?.rasterLayer!.rasterFiles.some((x) => x.date === mapDateStr)
      ? mapDateStr
      : gisFile?.rasterLayer!.rasterFiles[0].date;

    const inputValue =
      (moment(currentMapStr, "YYYYMMDD").diff(beginDate, "days") / endDate.diff(beginDate, "days")) * 100;
    const dateSelector = document.createElement("div");
    dateSelector.className = "date-selector";
    dateSelector.innerHTML = `
      <div class="labels">
        <span>2023</span>
        <span>2025</span>
      </div>
      <input
        type="range"
        id="date-filter"
        value=${inputValue}
        min=0
        max=100
      />
      <span class="date-display" id="selected-date">${mapDateStr}</span>
  `;
    // Add the React event handler
    const input = dateSelector.querySelector("input");
    if (input) {
      input.addEventListener("input", () => {
        const closestIndex = sliderPositions.reduce(
          (prev, curr, index) =>
            Math.abs(curr - Number(input.value)) < Math.abs(sliderPositions[prev] - Number(input.value)) ? index : prev,
          0
        );
        input.value = sliderPositions[closestIndex].toString(); // Snap to closest tick
        setMapDateStr(dateOptions[closestIndex]);
      });
    }
    return dateSelector;
  };

  const createMapInfoBox = (): HTMLDivElement => {
    const mapContextDiv = document.createElement("div");
    mapContextDiv.className = "map-context-container";
    mapContextDiv.innerHTML = `
      <div class="message-title">${mapContextHead}</div>
      <div class="message-content">${mapContextContent}</div>
  `;
    return mapContextDiv;
  };

  useEffect(() => {
    if (!mapElement.current) return undefined;
    if (gisFile?.rasterLayer) {
      minValue = gisFile.rasterLayer.rasterMinMax.minValue;
      maxValue = gisFile.rasterLayer.rasterMinMax.maxValue;
      noDataValue =
        gisFile.rasterLayer.rasterMinMax.noDataValue == null ? -99999 : gisFile.rasterLayer.rasterMinMax.noDataValue;
      color1 = gisFile?.rasterLayer.rasterFillColours[0].Colour;
      color1Text = gisFile?.rasterLayer.rasterFillColours[0].Text || null;
      color2 = gisFile?.rasterLayer.rasterFillColours.at(-1)!.Colour;
      color2Text = gisFile?.rasterLayer.rasterFillColours.at(-1)!.Text || null;
      if (gisFile?.rasterLayer.rasterFillColours.length === 3) {
        midColour = gisFile?.rasterLayer.rasterFillColours.at(1)!.Colour;
      }

      if (gisFile.mapContext) {
        mapContextHead = gisFile.mapContext.mapContextHead;
        mapContextContent = gisFile.mapContext.mapContextContent;
      }
      let currentMapStr;
      if (gisFile?.rasterLayer.rasterFiles.some((x) => x.date === mapDateStr)) {
        currentMapStr = mapDateStr;
      } else {
        currentMapStr = gisFile?.rasterLayer.rasterFiles[0].date;
        setMapDateStr(currentMapStr);
      }
      const geoTiffSource = new GeoTIFF({
        sources: [
          {
            url: gisFile?.rasterLayer.rasterFiles.find((x) => x.date === currentMapStr)?.geoTiffFileUrl,
            nodata: NaN,
          },
        ],
        interpolate: false,
        normalize: false,
      });
      const webGLLayer = new WebGLTile({
        source: geoTiffSource,
        style: {
          color:
            gisFile.rasterLayer.rasterFillColours.length <= 3
              ? [
                  "interpolate", // Use a value mapping approach
                  ["linear"], // Define the interpolation type
                  ["band", 1], // Read data from band 1 of the GeoTIFF
                  noDataValue,
                  `rgba(0, 0, 0, 0)`,
                  // 0,
                  // `rgba(0, 0, 0, 0)`,
                  minValue,
                  hexToRGB(color1, "1"), // Map the minimum value (e.g., 0) to color1 gisFile?.rasterLayer.rasterFillColours[0].Opacity || "0"
                  midColour ? (minValue + maxValue) / 2 : maxValue,
                  hexToRGB(midColour || color2, "1"),
                  maxValue,
                  hexToRGB(color2, "1"), // Map the maximum value (e.g., 255) to color2
                ]
              : [
                  "case",
                  ["==", ["band", 1], 1],
                  [135, 206, 228],
                  ["==", ["band", 1], 2],
                  [86, 89, 98],
                  ["==", ["band", 1], 3],
                  [95, 147, 117],
                  ["==", ["band", 1], 4],
                  [186, 207, 119],
                  ["==", ["band", 1], 5],
                  [255, 255, 255],
                  [0, 0, 0, 0.0],
                ],
        },
      });
      layers = [satelliteLayer, webGLLayer];
    } else {
      layers = [satelliteLayer, shapeLayer];
    }
    const mapObj = new Map({
      layers,
      view: new View({
        center: transform([coordinate.lng, coordinate.lat], "EPSG:4326", "EPSG:3857"),
        zoom: 14,
      }),
    });

    mapObj.setTarget(mapElement.current);

    mapObj.addOverlay(infoOverlay);
    mapObj.on("pointermove", (event) => {
      let data = null;
      if (gisFile.rasterLayer) {
        data = layers[1].getData(event.pixel);
      }
      // else if (gisFile.vectorFile) {
      //   mapObj.forEachFeatureAtPixel(event.pixel, (feature) => {
      //     const properties = feature.getProperties();
      //     data = properties[gisFile.vectorFile!.featurePropertyName];
      //     if (Array.isArray(data)) {
      //       data = data[dateArray.indexOf(mapDate)];
      //     }
      //   });
      // }
      // infoElement.style.display = "none";
      const displayNum = Number(data?.toString().split(",")[0]);
      if (displayNum) {
        // if (displayNum >= minValue && displayNum <= maxValue) {
        infoOverlay.setPosition(event.coordinate);
        infoElement.innerHTML = `${displayNum.toFixed(2)}`;
        infoElement.style.display = "block";
        // }
      }
    });

    let isRendercomplete = false;
    mapObj.on("rendercomplete", () => {
      if (isRendercomplete) return;
      if (gisFile?.vectorFile || gisFile?.rasterLayer) {
        mapObj.addControl(
          new Control({
            element: createColorLegend(),
          })
        );
      }
      if (gisFile.mapContext) {
        mapObj.addControl(
          new Control({
            element: createMapInfoBox(),
          })
        );
      }
      if (gisFile?.rasterLayer?.rasterFiles.length) {
        mapObj.addControl(
          new Control({
            element: createDateSelector(),
          })
        );
      }
      isRendercomplete = true;
    });
    return () => mapObj.setTarget("");
  }, [gisFile.mapContext, mapDateStr]);

  return <div id="map-openlayer-container" className="map-container" ref={mapElement} />;
};
